import React from "react"
import { Link } from "gatsby"

export default function PostLink(props) {
  const post = props.post

  return (
    <div style={{ margin: "0 0 0.5em 0" }}>
      <Link
        style={{
          color: "#838379",
          fontSize: "1.75em",
        }}
        to={post.fields.slug}
      >
        {post.frontmatter.title}
      </Link>
    </div>
  )
}
